<template>
  <section id="PPK">
    <ExperiencesCarousel :carouselImages="carouselImages" />
  </section>
</template>

<script>
  import ExperiencesCarousel from '@/components/global/ExperiencesCarousel'

  export default {
    name: "PPK",
    components: {
      ExperiencesCarousel
    },
    data() {
      return {
        carouselImages: [
			{
				image: 'Experiences/ppk/ppk-01.jpg',
				title: 'Punt, Pass, and Kick',
				description: "Test your guest’s skills with a punt, pass, and kick experience featuring four themed stations designed to excite attendees and create connections. This activation will be the talk of the event and will provide guests with lasting stories to tell.",
			},
			{
				image: 'Experiences/ppk/ppk-02.jpg',
			},
			{
				image: 'Experiences/ppk/ppk-03.jpg',
			},
			{
				image: 'Experiences/ppk/ppk-04.jpg',
			},
			{
				image: 'Experiences/ppk/ppk-05.jpg',
			},
			{
				image: 'Experiences/ppk/ppk-06.jpg',
			},
			{
				image: 'Experiences/ppk/ppk-07.jpg',
			}
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
